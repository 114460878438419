import { Box, Group, Image, SimpleGrid, Stack, Text, Title } from '@mantine/core';
import { useQuery } from '@tanstack/react-query';
import { ErrorComponent, ErrorComponentProps, getRouteApi } from '@tanstack/react-router';
import { dogParkLeaderboardOptions, LEADERBOARD_ENTRIES_LIMIT } from '../../utils/dogParkLeaderboard';
import { AxiosError } from 'axios';
import { DogParkLeaderboardEntry } from '../DogParkLeaderboardEntry/DogParkLeaderboardEntry';
import { DogParkLeaderboardEmptyEntry } from '../DogParkLeaderboardEmptyEntry/DogParkLeaderboardEmptyEntry';
import { Carousel } from '@mantine/carousel';
import { useRef } from 'react';
import Autoplay from 'embla-carousel-autoplay';
import { QRCode } from 'react-qrcode-logo';
import classes from './DogParkLeaderboard.module.css';

import FiLogo from '../../assets/fi_logo_yellow.svg';
import DogsRunning from '../../assets/pits_running_tall.mp4';

const route = getRouteApi('/dogpark/leaderboard/$id');

interface Testimonial {
  quote: string;
  attribution: string;
}

function getCodeUrl() {
  // TODO: update this with utm query params for tracking and coupon(?)
  // https://app.asana.com/0/1207101507783044/1207679714515266/f
  return 'https://tryfi.com';
}

export function DogParkLeaderboard() {
  const { id } = route.useParams();
  const { status, error, data, dataUpdatedAt } = useQuery(dogParkLeaderboardOptions(id));

  const autoplay = useRef(Autoplay({ delay: 4000 }));

  if (status === 'pending') {
    return <Text>Loading...</Text>;
  }

  if (status === 'error') {
    return <Text>Error! {error.message}</Text>;
  }

  const updateString = new Date(dataUpdatedAt).toLocaleString('en-US', { dateStyle: 'long', timeStyle: 'short' });

  // Calculate ranks to account for ties
  // If two or more entries have the same number of visits, they have the same rank (which is the next rank without any entries).
  // Otherwise, the rank should match the index + 1, since the API returns the data sorted by visit count.
  const calculatedRanks: number[] = [];
  data.leaderboard.forEach((entry, index, array) => {
    const previousEntry = index > 0 ? array[index - 1] : null;
    const rank =
      previousEntry && previousEntry.visitCount === entry.visitCount ? calculatedRanks[index - 1] : index + 1;
    calculatedRanks.push(rank);
  });

  const leaderboardEntries = data.leaderboard.map((entry, index) => {
    return <DogParkLeaderboardEntry entryData={entry} rank={calculatedRanks[index]} key={index} />;
  });

  // if the leaderboard is not full, add empty entries to fill it
  if (leaderboardEntries.length < LEADERBOARD_ENTRIES_LIMIT) {
    for (let i = leaderboardEntries.length; i < LEADERBOARD_ENTRIES_LIMIT; i++) {
      leaderboardEntries.push(<DogParkLeaderboardEmptyEntry rank={i + 1} key={i} />);
    }
  }

  const testimonials: Testimonial[] = [
    { quote: '“The peace of mind I get is worth every penny”', attribution: '@myheartbeatsatmyfeet' },
    { quote: '“I don’t worry about losing any of my six dogs now”', attribution: '@jeremygranthorton' },
    { quote: '“Helped me get my escape artist back!”', attribution: 'Rachel D.' },
    { quote: '“The best decision ever buying this GPS collar!!”', attribution: '@shirley2792' },
  ];

  const testimonialSlides = testimonials.map((t, i) => (
    <Carousel.Slide key={i}>
      <Stack className={classes.slide}>
        <Text className={classes.quoteText}>{t.quote}</Text>
        <Text className={classes.attributionText}>{t.attribution}</Text>
      </Stack>
    </Carousel.Slide>
  ));

  return (
    <Stack justify="center" align="stretch" bg="background-dark-primary" h="100vh">
      <Group wrap="nowrap" justify="center" align="center" gap="40px">
        <Stack className={classes.leaderboardContainer}>
          <Group wrap="nowrap" align="flex-start">
            <Image src={FiLogo} bg="fi-yellow" radius="sm" />
            <Title className={classes.leaderboardTitle}>Top Dogs at {data.name}</Title>
          </Group>
          <Text className={classes.leaderboardSubtitle}>
            Dogs with the most visits last month. Get a Fi collar to join the chase!
          </Text>
          <SimpleGrid cols={2} spacing="16px">
            <Stack gap="16px">{leaderboardEntries.slice(0, 3)}</Stack>
            <Stack gap="16px">{leaderboardEntries.slice(3)}</Stack>
          </SimpleGrid>
          <Text c="type-dark-secondary" fz="12px" fw={500} lh="140%">
            Based on last 28 days, updated {updateString}
          </Text>
        </Stack>
        <Stack align="center" className={classes.marketingSidebar}>
          <video autoPlay muted loop className={classes.videoBackground}>
            <source src={DogsRunning} type="video/mp4" />
          </video>
          <Title className={classes.marketingTitle}>The smarter tracking collar, for safer, healthier dogs.</Title>
          <Box maw="100%" mt="auto">
            <Carousel
              loop
              draggable={false}
              withControls={false}
              slideSize="256px"
              slideGap="8px"
              plugins={[autoplay.current]}
            >
              {testimonialSlides}
            </Carousel>
          </Box>
          <Group className={classes.marketingFooter} wrap="nowrap">
            <QRCode size={56} value={getCodeUrl()} qrStyle="dots" style={{ borderRadius: '6px' }} />
            <Stack gap="0px">
              <Text className={classes.promoText}>Visit tryfi.com</Text>
              <Text className={classes.promoText}>to learn more</Text>
            </Stack>
          </Group>
        </Stack>
      </Group>
    </Stack>
  );
}

export function DogParkLeaderboardError(props: ErrorComponentProps) {
  const { error } = props;
  if (error instanceof AxiosError) {
    return (
      <Box>
        <Title>Uh-oh!</Title>
        <Text>Something went wrong!</Text>
        <Text>Error: {error.message}</Text>
      </Box>
    );
  }
  return <ErrorComponent error={error} />;
}
