import analytics from '.';

export function resetAnalytics() {
  analytics.reset();
}

export const liveTrackingEvents = {
  shareClicked: () => {
    analytics.track('Live Tracking Web View > Share Clicked', {});
  },
  ownerMessageSent: (properties: { messageLength: number; messageContent: string }) => {
    analytics.track('Live Tracking Web View > Owner Message Sent', properties);
  },
  directionsClicked: () => {
    analytics.track('Live Tracking Web View > Directions Clicked', {});
  },
  learnMoreClicked: () => {
    analytics.track('Live Tracking Web View > Learn More Clicked', {});
  },
  messageFormOpened: () => {
    analytics.track('Live Tracking Web View > Message Form Opened', {});
  },
};

export const communityPostEvents = {
  commentsClicked: (postId: string) => {
    analytics.track('Community Post > Comments Clicked', { id: postId });
  },
  shareClicked: (postId: string) => {
    analytics.track('Community Post > Share Clicked', { id: postId });
  },
  joinFiHeaderClicked: (postId: string) => {
    analytics.track('Community Post > Join Fi Header Clicked', { id: postId });
  },
  videoPostViewed: (postId: string) => {
    analytics.track('Community Post > Video Post Viewed', { id: postId });
  },
  photoPostViewed: (postId: string) => {
    analytics.track('Community Post > Photo Post Viewed', { id: postId });
  },
  textPostViewed: (postId: string) => {
    analytics.track('Community Post > Text Post Viewed', { id: postId });
  },
  doubleTappedLike: (postId: string) => {
    analytics.track('Community Post > Double Tapped Like', { id: postId });
  },
  joinFiClicked: (postId: string) => {
    analytics.track('Community Post > Join Fi Clicked', { id: postId });
  },
  joinFiCloseClicked: (postId: string) => {
    analytics.track('Community Post > Join Fi Close Clicked', { id: postId });
  },
  joinFiFooterClicked: (postId: string) => {
    analytics.track('Community Post > Join Fi Footer Clicked', { id: postId });
  },
  viewedOtherPosts: (postId: string) => {
    analytics.track('Community Post > Viewed Other Posts', { id: postId });
  },
};
