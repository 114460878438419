import { Anchor, Container, Image, SimpleGrid, Text } from '@mantine/core';
import { useInView } from 'react-intersection-observer';
import { communityPostEvents } from '../../lib/analytics/events.ts';
import { PublicPost } from '../../utils/publicPost.ts';
import classes from './PublicPostRecentPosts.module.css';
import VideoPlay from '../../assets/video-play.svg';

export function PublicPostRecentPosts({
  petName,
  posts,
  parentPostId,
}: {
  petName: string;
  posts: PublicPost[];
  parentPostId: string;
}) {
  const handleContainerViewChange = (inView: boolean) => {
    if (inView) {
      communityPostEvents.viewedOtherPosts(parentPostId);
    }
  };
  const { ref: visibilityContainer } = useInView({
    triggerOnce: true,
    onChange: handleContainerViewChange,
    threshold: 1,
  });

  const tiles = posts.map((post) => {
    switch (post.type) {
      case 'photo':
        return (
          <Anchor className={classes.tile} href={`/post/${post.id}`} key={post.id}>
            <Image src={post.url} />
          </Anchor>
        );
      case 'video':
        return (
          <Anchor className={classes.tile} href={`/post/${post.id}`} key={post.id}>
            <Image src={VideoPlay} className={classes.playIcon} />
            <Image src={post.thumbnailUrl} />
          </Anchor>
        );
      case 'text':
        return (
          <Anchor className={classes.tile} href={`/post/${post.id}`} key={post.id}>
            <Text className={classes.text} bg={post.backgroundColorHex} c={post.textColorHex}>
              {post.text}
            </Text>
          </Anchor>
        );
    }
  });
  return (
    <Container className={classes.container} size={800}>
      <Text className={classes.title} truncate={'end'} maw={'90%'}>
        More from <Text span>{petName}</Text> on Fi
      </Text>
      <SimpleGrid cols={3} spacing={3} mt={20} ref={visibilityContainer}>
        {tiles}
      </SimpleGrid>
    </Container>
  );
}
