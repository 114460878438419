import { Button, CloseButton, Image, Paper, Stack, Text, TextInput, Textarea, Title } from '@mantine/core';
import { hasLength, useForm } from '@mantine/form';
import { liveTrackingEvents } from '../../lib/analytics/events.ts';
import classes from './LiveSharingContactForm.module.css';
import { useMutation } from '@tanstack/react-query';
import { sendMessage } from '../../utils/liveSharing';
import { isPossiblePhoneNumber } from 'libphonenumber-js';

export function LiveSharingContactForm(props: { petName: string; sharingId: string; onClose: () => void }) {
  const { petName, sharingId, onClose } = props;
  const form = useForm({
    mode: 'uncontrolled',
    initialValues: {
      name: '',
      phoneNumber: '',
      message: '',
    },
    validate: {
      name: hasLength({ max: 50 }, 'Name can only be 50 characters long'),
      phoneNumber: (value) => (isPossiblePhoneNumber(value, 'US') ? null : 'Invalid phone number'),
      message: hasLength({ max: 250 }, 'Message can only be 250 characters long'),
    },
  });

  const mutation = useMutation({
    mutationFn: sendMessage,
    onSuccess: (_data, variables) => {
      liveTrackingEvents.ownerMessageSent({
        messageLength: variables.message.length,
        messageContent: variables.message,
      });
    },
  });

  return (
    <Paper radius="lg" withBorder shadow="xs" className={classes.root}>
      <Stack gap="4px">
        <CloseButton onClick={onClose} size="lg" className={classes.closeButton} />
        <Stack gap="16px">
          <Stack gap="12px">
            <Title fz="40px">Message {petName}'s owner</Title>
            <Text fw={600} fz="16px">
              If you've seen or recovered {petName}, send a message to help them reunite with their owner.
            </Text>
          </Stack>
          <form onSubmit={form.onSubmit((values) => mutation.mutate({ sharingId, ...values }))}>
            <Stack gap="16px">
              <TextInput
                required
                aria-label="Name"
                placeholder="Name"
                key={form.key('name')}
                size="md"
                radius="xl"
                autoComplete="name"
                disabled={mutation.isPending || mutation.isSuccess}
                {...form.getInputProps('name')}
              />
              <TextInput
                required
                aria-label="Phone number"
                placeholder="Phone number"
                key={form.key('phoneNumber')}
                size="md"
                radius="xl"
                autoComplete="tel"
                disabled={mutation.isPending || mutation.isSuccess}
                {...form.getInputProps('phoneNumber')}
              />
              <Textarea
                required
                aria-label="Message"
                placeholder="Leave a message for the owner"
                key={form.key('message')}
                size="md"
                radius="lg"
                disabled={mutation.isPending || mutation.isSuccess}
                rows={4}
                {...form.getInputProps('message')}
              />
              {mutation.isError && <Text c="red">Uh-oh! Something went wrong. Please try again later.</Text>}
              <Button
                className={classes.sendButton}
                color="dark.9"
                type="submit"
                radius="xl"
                autoContrast
                disabled={mutation.isPending || mutation.isSuccess}
                loading={mutation.isPending}
                leftSection={mutation.isSuccess && <Image src="/check_icon.svg" />}
              >
                {mutation.isSuccess ? 'Message sent' : 'Send'}
              </Button>
            </Stack>
          </form>
        </Stack>
      </Stack>
    </Paper>
  );
}
