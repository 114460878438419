import { Avatar, Group, Image, Text } from '@mantine/core';
import classes from './DogParkLeaderboardEmptyEntry.module.css';

import DogIcon from '../../assets/dog_icon_background_dark.svg';
import AwardFirst from '../../assets/award_first.svg';
import AwardSecond from '../../assets/award_second.svg';
import AwardThird from '../../assets/award_third.svg';
import AwardBlank from '../../assets/award_blank.svg';

export function DogParkLeaderboardEmptyEntry(props: { rank: number }) {
  const { rank } = props;

  const topThree = rank <= 3;

  return (
    <Group align="center" wrap="nowrap" className={classes.box}>
      <Avatar src={DogIcon} radius="100px" size={topThree ? '88px' : '56px'} />
      <Text className={classes.text} truncate>
        No dog yet, claim this spot!
      </Text>
      {rank === 1 && <Image src={AwardFirst} />}
      {rank === 2 && <Image src={AwardSecond} />}
      {rank === 3 && <Image src={AwardThird} />}
      {!topThree && (
        <Group pos="relative" flex="0 0 auto">
          <Image src={AwardBlank} />
          <Text className={classes.rankOverlay}>{rank}</Text>
        </Group>
      )}
    </Group>
  );
}
