import { useEffect, useState } from 'react';

/**
 * Uses session storage to persist the mute state of video.
 */
export function useVideoMute() {
  const [isMuted, setMuted] = useState(() => {
    const storedValue = sessionStorage.getItem('isMuted');
    const parsedStoredValue = storedValue && JSON.parse(storedValue);
    if (typeof parsedStoredValue !== 'boolean') {
      // Default to muted
      return true;
    }

    return parsedStoredValue;
  });

  useEffect(() => {
    sessionStorage.setItem('isMuted', JSON.stringify(isMuted));
  }, [isMuted]);

  return [isMuted, setMuted] as const;
}
