import { Button, Divider, Modal, Text, Image, Flex } from '@mantine/core';
import FiLogo from '../../assets/fi_logo_black.svg';
import { communityPostEvents } from '../../lib/analytics/events.ts';
import classes from './PublicPostJoin.module.css';

export function PublicPostJoin({ opened, close, postId }: { opened: boolean; close: () => void; postId: string }) {
  return (
    <Modal
      opened={opened}
      onClose={close}
      withCloseButton={false}
      radius={24}
      centered
      overlayProps={{ blur: 25 }}
      size={308}
    >
      <Flex direction={'column'} justify={'center'} align={'center'} p={4}>
        <Image w={54} h={54} src={FiLogo} alt="Fi Logo" />
        <Text fz={20} fw={700} lh={'120%'} ta={'center'}>
          Join Fi to like or comment on posts.
        </Text>
        <Button
          component={'a'}
          w={'100%'}
          href={'https://tryfi.com'}
          mt={32}
          radius={'xl'}
          color={'black'}
          fz={16}
          fw={600}
          h={55}
          className={classes.borderlessButton}
          onClick={() => {
            communityPostEvents.joinFiClicked(postId);
          }}
        >
          Join Fi
        </Button>
        <Button
          w={'100%'}
          h={45}
          onClick={() => {
            communityPostEvents.joinFiCloseClicked(postId);
            close();
          }}
          bg={'transparent'}
          c={'#000000'}
          mt={8}
          className={classes.borderlessButton}
        >
          Close
        </Button>
        <Divider w={'100%'} c={'#F1F1EF'} my={12} />
        <Text fz={14} fw={400} lh={'140%'} ta={'center'} c={'#737373'}>
          Already have Fi? Log in to your account on the Fi mobile app.
        </Text>
      </Flex>
    </Modal>
  );
}
