import { queryOptions } from '@tanstack/react-query';
import axios from 'axios';

export const LEADERBOARD_ENTRIES_LIMIT = 8;

export interface LeaderboardEntry {
  name: string;
  stepsThisMonth: number;
  age: string;
  breed?: string;
  visitCount: number;
  photoUrl?: string;
}

interface LeaderboardData {
  name: string;
  leaderboard: LeaderboardEntry[];
}

export const dogParkLeaderboardOptions = (id: string, autoRefresh: boolean = true) =>
  queryOptions({
    queryKey: ['dogParkId', { id }],
    queryFn: () => getDogParkLeaderboardData(id),
    refetchInterval: autoRefresh ? 300_000 : false, // 5 minute refresh interval
  });

async function getDogParkLeaderboardData(id: string) {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';
  const response = await axios.get<LeaderboardData>(
    `${webApiBaseUrl}/dog-parks/leaderboard/${id}?limit=${LEADERBOARD_ENTRIES_LIMIT}`,
  );
  return { ...response.data };
}
