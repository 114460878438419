import { BackgroundImage, Badge, Stack } from '@mantine/core';
import StaticMap from '../assets/static_map.png';
import classes from './InactiveMap.module.css';

export function InactiveMap() {
  return (
    <BackgroundImage src={StaticMap} className={classes.root} radius="md">
      <Stack align="center" justify="center" h="100%">
        <Badge
          className={classes.trackingEndedBadge}
          color="rgba(255, 255, 255, 0.9)"
          h="44px"
          radius="md"
          autoContrast
        >
          Tracking has ended
        </Badge>
      </Stack>
    </BackgroundImage>
  );
}
