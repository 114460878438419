import { Anchor, Button, Container, Flex, Image, Text, TextInput } from '@mantine/core';
import { useForm } from '@mantine/form';
import { BaseSyntheticEvent, useCallback, useState } from 'react';
import AppleAppStoreLogo from '../../assets/apple-app-store.svg';
import GooglePlayStoreLogo from '../../assets/google-app-store.svg';
import FacebookLogo from '../../assets/icon-facebook.svg';
import InstagramLogo from '../../assets/icon-instagram.svg';
import PinterestLogo from '../../assets/icon-pinterest.svg';
import TikTokLogo from '../../assets/icon-tiktok.svg';
import TwitterLogo from '../../assets/icon-twitter.svg';
import classes from './Footer.module.css';

const SIGNUP_SOURCE = 'community_site';

export function Footer() {
  const [submitted, setSubmitted] = useState(false);

  const form = useForm({
    validate: {
      email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
    },
  });
  const handleSubmit = useCallback(
    (e: BaseSyntheticEvent) => {
      e.preventDefault();
      form.validate();

      setSubmitted(true);
      const email = form.getValues().email;

      const payload = {
        id: email.toLowerCase(),
        email: email,
        source: SIGNUP_SOURCE,
        created_at: Math.round(new Date().getTime() / 1000),
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      (window as any)._cio.identify(payload);

      return false;
    },
    [form],
  );

  return (
    <footer>
      <Container className={classes.container}>
        <Flex className={classes.flexWrapper} justify={'space-between'}>
          <Flex direction={'column'} justify={'space-between'}>
            <Flex direction={'column'} gap={16}>
              <Anchor fw={500} c={'#000000'} href={'https://jobs.lever.co/fi'}>
                Jobs
              </Anchor>
              <Anchor fw={500} c={'#000000'}>
                Press
              </Anchor>
              <Anchor fw={500} c={'#000000'}>
                Privacy policy
              </Anchor>
              <Anchor fw={500} c={'#000000'}>
                Support
              </Anchor>
              <Anchor fw={500} c={'#000000'}>
                Nano
              </Anchor>
            </Flex>

            <div className={classes.desktopOnly}>
              <Text>7 days a week 10AM - 8PM EST</Text>
              <Text>© Barking Labs</Text>
            </div>
          </Flex>
          <Flex className={classes.joinNewsletterWrapper} direction={'column'} gap={10}>
            <Text fw={500} fz={24}>
              Join our newsletter
            </Text>
            <Text fz={16} fw={400} c={'#737373'}>
              All the dog news you'll obsess over, in
              <br /> one place, twice a month.
            </Text>

            <form onSubmit={handleSubmit}>
              <div className={classes.grid}>
                <TextInput
                  disabled={submitted}
                  w={'100%'}
                  key={form.key('email')}
                  {...form.getInputProps('email')}
                  size={'lg'}
                  h={68}
                  radius={100}
                  placeholder={'Enter email'}
                />
                <Button
                  className={`${classes.borderlessButton} ${classes.newsletterSubmitButton}`}
                  fz={23}
                  bg={'transparent'}
                  pos={'absolute'}
                  c={'#000000'}
                  onClick={handleSubmit}
                >
                  &gt;
                </Button>
              </div>
            </form>
            {submitted && <div>Thank you for joining our list!</div>}

            <Flex className={classes.appStoreButtons} justify={'space-between'}>
              <Button
                className={classes.appStoreButton}
                component={'a'}
                href={'https://apps.apple.com/us/app/fi-gps-dog-tracker/id1438036784'}
              >
                <Image src={AppleAppStoreLogo} />
              </Button>
              <Button
                className={classes.appStoreButton}
                component={'a'}
                href={'https://play.google.com/store/apps/details?id=com.barkinglabs.fi'}
              >
                <Image src={GooglePlayStoreLogo} />
              </Button>
            </Flex>

            <Flex className={classes.socialButtons} direction={'row'} align={'center'} gap={4}>
              <Anchor className={classes.socialButton} href={'https://www.facebook.com/dogcollarsbyfi/'}>
                <Image src={FacebookLogo} />
              </Anchor>
              <Anchor className={classes.socialButton} href={'https://www.instagram.com/fi.dogs/'}>
                <Image src={InstagramLogo} />
              </Anchor>
              <Anchor className={classes.socialButton} href={'https://twitter.com/fi_dogs'}>
                <Image src={TwitterLogo} />
              </Anchor>
              <Anchor className={classes.socialButton} href={'https://www.pinterest.com/fidogs/'}>
                <Image src={PinterestLogo} />
              </Anchor>
              <Anchor className={classes.socialButton} href={'https://www.tiktok.com/@fi.dogs'}>
                <Image src={TikTokLogo} />
              </Anchor>
            </Flex>
          </Flex>
        </Flex>
      </Container>
    </footer>
  );
}
