// Copied from https://segment.com/docs/connections/spec/identify/#traits
export interface IdentifyTraits {
  address?: {
    city?: string;
    country?: string;
    postalCode?: string;
    state?: string;
    street?: string;
  };
  age?: number;
  avatar?: string;
  birthday?: Date;
  company?: {
    name?: string;
    id?: string | number;
    industry?: string;
    employee_count?: number;
    plan?: string;
  };
  createdAt?: Date;
  description?: string;
  email?: string;
  firstName?: string;
  gender?: string;
  id?: string; // Unique ID in your database for a user
  lastName?: string;
  name?: string;
  phone?: string;
  title?: string;
  username?: string;
  website?: string;
}

// For reference: https://segment.com/docs/connections/spec/common/#context
interface SegmentContext extends Record<string, any> {
  traits?: IdentifyTraits;
}

export interface IAnalyticsBackend {
  identify(userId: string): void;
  identify(userId: string, traits?: IdentifyTraits): void;
  identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts): void;
  page(): void;
  track(name: string, properties: any): void;
  track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void;
  ready(callback: () => void): void;
  trackLink(element: HTMLElement, name: string, properties?: any): void;
  /** Reset anonymous ID */
  reset(): void;

  anonymousId(): string;
  userId(): string;
  userTraits(): IdentifyTraits;
}

interface ISegmentAnalyticsSource {
  analytics: SegmentAnalytics.AnalyticsJS;
}

function augmentProperties(properties: any) {
  let timezone: string | undefined;
  try {
    timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  } catch (e) {
    // Noop
  }

  return {
    ...properties,
    timezone,
  };
}

class SegmentAnalytics implements IAnalyticsBackend {
  private source: ISegmentAnalyticsSource;

  constructor(source: ISegmentAnalyticsSource) {
    this.source = source;
  }

  identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
    if (typeof this.source.analytics.identify !== 'function') {
      return;
    }

    this.source.analytics.identify(userId, traits, options);
  }

  page(): void {
    if (typeof this.source.analytics.page !== 'function') {
      return;
    }

    this.source.analytics.page(augmentProperties({}), this.augmentSegmentOptions());
  }

  track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void {
    if (typeof this.source.analytics.track !== 'function') {
      return;
    }

    this.source.analytics.track(name, augmentProperties(properties), this.augmentSegmentOptions(options));
  }

  trackLink(element: HTMLElement, name: string, properties: any = {}) {
    if (typeof this.source.analytics.trackLink !== 'function') {
      return;
    }

    this.source.analytics.trackLink(element, name, properties);
  }

  ready(callback: () => void) {
    if (typeof this.source.analytics.ready !== 'function') {
      return;
    }

    this.source.analytics.ready(callback);
  }

  reset() {
    if (typeof this.source.analytics.reset !== 'function') {
      return;
    }

    this.source.analytics.reset();
  }

  anonymousId() {
    if (typeof this.source.analytics.user !== 'function') {
      return '';
    }

    return this.source.analytics.user().anonymousId();
  }

  userId() {
    if (typeof this.source.analytics.user !== 'function') {
      return '';
    }

    return this.source.analytics.user().id() ?? '';
  }

  userTraits(): IdentifyTraits {
    if (typeof this.source.analytics.user !== 'function') {
      return {};
    }

    // The segment types are incorrect on this, you can access traits in this fashion, just
    // run `analytics.user().traits()` in the site's browser console to verify
    // Also in code: https://github.com/segmentio/analytics.js/blob/7dda4259d40375666ea6fd166d51b208d79c38ec/analytics.js#L5040
    return this.source.analytics.user().traits() as unknown as IdentifyTraits;
  }

  private augmentSegmentOptions(options?: SegmentAnalytics.SegmentOpts) {
    // This is not always defined
    if (typeof this.source.analytics.user !== 'function') {
      return options;
    }

    const userTraits = this.userTraits();

    // Short circuit if we have no user traits to merge in
    if (!userTraits || Object.keys(userTraits).length === 0) {
      return options;
    }

    // Short circuit if we have user traits, but no base options were given
    if (!options) {
      return { context: { traits: userTraits } };
    }

    // Merge traits into options giving preference to the options.context.traits over user.traits
    const { traits: contextTraits, ...originalContext } = (options?.context ?? {}) as SegmentContext;
    const combinedTraits = contextTraits ? { ...userTraits, ...contextTraits } : userTraits;
    return {
      ...options,
      context: { ...originalContext, traits: combinedTraits },
    };
  }
}

class LogAnalytics implements IAnalyticsBackend {
  private _userId = '';
  private _traits: IdentifyTraits = {};

  identify(userId: string, traits?: IdentifyTraits, options?: SegmentAnalytics.SegmentOpts) {
    console.log('Identifying user as: ', userId, traits, options);
    this._userId = userId;
    this._traits = traits ?? {};
  }

  page(): void {
    console.log('Analytics: page');
  }

  reset(): void {
    console.log('Analytics: reset');
  }

  track(name: string, properties: any, options?: SegmentAnalytics.SegmentOpts): void {
    console.log(
      'Analytics: track - name: ',
      name,
      ', properties: ',
      augmentProperties(properties),
      ', options: ',
      options,
    );
  }

  ready(callback: () => void) {
    callback();
  }

  trackLink() {
    // Noop
  }

  anonymousId(): string {
    return '';
  }

  userId(): string {
    return this._userId;
  }

  userTraits(): IdentifyTraits {
    return this._traits;
  }
}

const analytics = (window as any).analytics ? new SegmentAnalytics(window as any) : new LogAnalytics();

export default analytics;
