import { createFileRoute } from '@tanstack/react-router';
import { DogParkLeaderboard, DogParkLeaderboardError } from '../components/DogParkLeaderboard/DogParkLeaderboard';
import { captureException } from '@sentry/react';
import { dogParkLeaderboardOptions } from '../utils/dogParkLeaderboard';

export const Route = createFileRoute('/dogpark/leaderboard/$id')({
  component: DogParkLeaderboard,
  onError: ({ error }) => {
    captureException(error);
  },
  errorComponent: DogParkLeaderboardError,
  loader: ({ context: { queryClient }, params }) => {
    return queryClient.ensureQueryData(dogParkLeaderboardOptions(params.id));
  },
});
