import { Avatar, Button, Card, Container, Divider, Flex, Group, Image, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import Heart from '../../assets/heart.svg';
import Share from '../../assets/share.svg';
import AudioMute from '../../assets/audio-mute.svg';
import { communityPostEvents } from '../../lib/analytics/events.ts';
import { useVideoMute } from '../../lib/useVideoMute.ts';
import { PublicPet, PublicPost } from '../../utils/publicPost.ts';
import { PublicPostJoin } from '../PublicPostJoin/PublicPostJoin.tsx';
import { handleShareClick } from '../../utils/handlers.tsx';
import classes from './PublicPostCard.module.css';

export function PublicPostCard({ post, pet }: { post: PublicPost; pet: PublicPet }) {
  const [isMuted, setMuted] = useVideoMute();

  const [joinOpened, { open: joinOpen, close: joinClose }] = useDisclosure(false);

  return (
    <>
      <PublicPostJoin postId={post.id} opened={joinOpened} close={joinClose} />
      <Card withBorder className={classes.card} p={0} radius={12}>
        <Group className={classes.mobileOnly} px={20} py={16} wrap={'nowrap'}>
          <Avatar w={38} h={38} src={pet.avatarUrl} alt={pet.name} radius={'xl'} />
          <div className={classes.petNameWrapper}>
            <Group gap={5} wrap={'nowrap'}>
              <Text fz={16} fw={600} lh={'120%'} maw={'40%'} truncate={'end'}>
                {pet.name}
              </Text>
              <Text c={'#737373'}>·</Text>
              <Text fz={14} fw={500} lh={'140%'} c={'#737373'}>
                {pet.cityState}
              </Text>
            </Group>
            <Group>
              <Text fz={14} fw={500} c={'#737373'} lh={'140%'}>
                @{pet.fiHandle}
              </Text>
            </Group>
          </div>
        </Group>

        {post.type === 'photo' && (
          <div
            className={classes.imageWrapper}
            onDoubleClick={() => {
              communityPostEvents.doubleTappedLike(post.id);
            }}
          >
            <Image src={post.url} alt={post.caption} />
          </div>
        )}
        {post.type === 'video' && (
          <div
            className={classes.videoWrapper}
            onDoubleClick={() => {
              communityPostEvents.doubleTappedLike(post.id);
            }}
          >
            {isMuted && <Image className={classes.audioMute} src={AudioMute} alt={'mute'} />}
            <video
              className={post.aspectRatio.width < post.aspectRatio.height ? classes.cover : ''}
              autoPlay
              playsInline
              muted={isMuted}
              onClick={() => setMuted(!isMuted)}
              loop={true}
            >
              <source src={post.mp4Url} type={'video/mp4'} />
            </video>
          </div>
        )}
        {post.type === 'text' && (
          <div
            className={classes.textWrapper}
            style={{
              color: post.textColorHex,
              backgroundColor: post.backgroundColorHex,
            }}
            onDoubleClick={() => {
              communityPostEvents.doubleTappedLike(post.id);
            }}
          >
            <Text fw={600} lh={'120%'}>
              {post.text}
            </Text>
          </div>
        )}

        <Container className={classes.container} style={{ overflowY: 'scroll' }}>
          <Group className={classes.desktopOnly} preventGrowOverflow={true} wrap={'nowrap'}>
            <Avatar src={pet.avatarUrl} alt={pet.name} radius={'xl'} />
            <div>
              <Group wrap={'nowrap'}>
                <Text fz={16} fw={600} lh={'120%'} maw={'50%'} lineClamp={1}>
                  {pet.name}
                </Text>
                <Text c={'#737373'} style={{ flexGrow: 1, textAlign: 'center' }}>
                  ·
                </Text>
                <Text
                  fz={14}
                  fw={500}
                  lh={'140%'}
                  c={'#737373'}
                  lineClamp={1}
                  style={{ flexGrow: 1, textAlign: 'right' }}
                >
                  {pet.cityState}
                </Text>
              </Group>
              <Group>
                <Text fz={14} fw={500} c={'#737373'} lh={'140%'}>
                  @{pet.fiHandle}
                </Text>
              </Group>
            </div>
          </Group>

          <Divider className={classes.desktopOnly} c={'#F1F1EF'} w={'100%'} my={16} />

          <Group wrap={'nowrap'}>
            <Group gap={6} w={'100%'}>
              <Text
                className={`${classes.alignCenter} ${classes.clickable}`}
                fz={14}
                fw={600}
                lh={'120%'}
                onClick={() => {
                  communityPostEvents.commentsClicked(post.id);
                  joinOpen();
                }}
              >
                <Image mr={5} src={Heart} width={22} height={20} alt={'Like'} />

                {post.likeCount > 0
                  ? `${post.likeCount} like${post.likeCount !== 1 ? 's' : ''} on Fi`
                  : `Like this on Fi`}
              </Text>
              <Button
                className={`${classes.flexEnd} ${classes.borderlessButton}`}
                p={0}
                bg={'none'}
                onClick={() => {
                  communityPostEvents.shareClicked(post.id);
                  handleShareClick();
                }}
              >
                <Image src={Share} width={22} height={20} />
                <Text c={'black'} fz={14} fw={600} lh={'120%'}>
                  Share
                </Text>
              </Button>
            </Group>
          </Group>

          <Group className={`${classes.commentWrapper} ${classes.clickable}`} onClick={joinOpen}>
            {/*Text posts don't have a caption */}
            {'caption' in post && (
              <Group className={classes.captionWrapper}>
                <Text>{post.caption}</Text>
              </Group>
            )}

            {post.commentAvatarUrls && post.commentAvatarUrls.length > 0 && (
              <Flex maw={60} direction={'row'} gap={0} mr={2}>
                {post.commentAvatarUrls.map((url) => (
                  <img width={30} height={30} className={classes.circle} src={url} key={url} />
                ))}
              </Flex>
            )}
            <Text className={classes.commentText}>
              {post.commentCount > 0
                ? `${post.commentCount} comment${post.commentCount !== 1 ? 's' : ''}`
                : 'No comments yet'}
            </Text>
            <Text mx={6} c={'#737373'}>
              ·
            </Text>
            <Text fz={14} fw={500} lh={'140%'} c={'#737373'} lts={-0.14}>
              {post.commentCount > 0 ? 'Join the conversation on Fi' : 'Post a comment on Fi'}
            </Text>
          </Group>
        </Container>
      </Card>
    </>
  );
}
