import { Button, Card, Flex, Text } from '@mantine/core';
import classes from './JoinFiCommunityCard.module.css';

export function JoinFiCommunityCard({ onJoinClick }: { onJoinClick: () => void }) {
  return (
    <Card className={classes.card} bg={'#242424'} px={20} py={24}>
      <Flex direction={'row'} align={'center'} justify={'space-between'} gap={12}>
        <div>
          <Text c={'#FFFFFF'} fz={18} fw={600} lh={'120%'}>
            Join the Fi Community
          </Text>
          <Text mt={6} c={'#9E9E9C'} fz={16} fw={400} lh={'140%'}>
            Connect with thousands of dogs, plus 24/7 live GPS tracking, activity, and sleep monitoring.
          </Text>
        </div>
        <Button className={classes.joinButton} component={'a'} href={'https://tryfi.com'} onClick={onJoinClick}>
          Join Fi
        </Button>
      </Flex>
    </Card>
  );
}
