import { Avatar, Badge, Box, Group, Image } from '@mantine/core';
import { LocationData } from '../utils/liveSharing';
import classes from './ActiveMap.module.css';
import { Map, AdvancedMarker, useMap } from '@vis.gl/react-google-maps';
import { useState } from 'react';
import { Circle } from './mapsGeometry/circle';
import SearchPartyIcon from '../assets/search_party_icon.svg';
import DogIcon from '../assets/dog_icon.svg';
import WarningIcon from '../assets/warning_icon.svg';
import OnlineIcon from '../assets/online_icon.svg';

function DogMarker(props: { locationData: LocationData; profilePhoto?: string }) {
  const { locationData, profilePhoto } = props;
  return (
    <>
      <Circle
        center={{ lat: locationData.lat, lng: locationData.long }}
        radius={locationData.accuracyRadius}
        strokeOpacity={0}
        fillColor="#1360F4"
        fillOpacity={0.2}
      />
      <AdvancedMarker position={{ lat: locationData.lat, lng: locationData.long }}>
        <div className={classes.pinContainer}>
          <div className={classes.warningBubble}>
            <div className={classes.warningIconContainer}>
              <Image src={WarningIcon} />
            </div>
          </div>
          <Image classNames={{ root: classes.pinBack }} src="/pin_back.svg" />
          <Avatar classNames={{ root: classes.pinAvatar }} src={profilePhoto} size="48px">
            <Image src={DogIcon} />
          </Avatar>
        </div>
      </AdvancedMarker>
    </>
  );
}

export function ActiveMap(props: {
  locationData: LocationData | null;
  profilePhoto?: string;
  visitorCount?: number;
  locationDelay: { isDelayed: boolean; delayMins: number | null };
}) {
  const { locationData, profilePhoto, visitorCount, locationDelay } = props;

  const map = useMap();

  const [initialMapCenter, setInitialMapCenter] = useState<{ lat: number; lng: number } | undefined>(undefined);
  const [lastLocation, setLastLocation] = useState<{ lat: number; lng: number } | undefined>(undefined);

  // set the initial map center based on the location data or a default location
  if (!initialMapCenter) {
    if (locationData) {
      setInitialMapCenter({ lat: locationData.lat, lng: locationData.long });
    } else {
      // Fi's office
      setInitialMapCenter({ lat: 40.728645, lng: -73.99207 });
    }
  }

  if (locationData && (lastLocation?.lat !== locationData?.lat || lastLocation?.lng !== locationData?.long)) {
    setLastLocation({ lat: locationData.lat, lng: locationData.long });
    map?.panTo({ lat: locationData.lat, lng: locationData.long });
  }

  const marker = locationData ? <DogMarker locationData={locationData} profilePhoto={profilePhoto} /> : <></>;

  const viewsBadge =
    visitorCount && visitorCount > 0 ? (
      <Badge
        className={classes.viewCountOverlay}
        leftSection={<Image src={SearchPartyIcon} />}
        color="rgba(255, 255, 255, 0.9)"
        radius="md"
        size="xl"
        autoContrast
      >
        {visitorCount} viewers
      </Badge>
    ) : (
      <></>
    );

  return (
    <Box className={classes.mapContainer}>
      <Group className={classes.mapOverlayGroup}>
        {viewsBadge}
        <Badge color="transparent" leftSection={<Image src={OnlineIcon} />} className={classes.trackingStatusOverlay}>
          {locationDelay.isDelayed ? `${locationDelay.delayMins} mins ago` : 'Live'}
        </Badge>
      </Group>
      <Map
        defaultCenter={initialMapCenter}
        defaultZoom={18}
        disableDefaultUI
        mapId={import.meta.env.VITE_GOOGLE_MAP_ID}
      >
        {marker}
      </Map>
    </Box>
  );
}
