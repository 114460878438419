import { communityPostEvents } from '../../lib/analytics/events.ts';
import { Footer } from '../Footer/Footer.tsx';
import { JoinFiCommunityCard } from '../JoinFiCommunityCard/JoinFiCommunityCard.tsx';
import { PublicPostCard } from '../PublicPostCard/PublicPostCard.tsx';
import { PublicPostRecentPosts } from '../PublicPostRecentPosts/PublicPostRecentPosts.tsx';
import classes from './PublicPost.module.css';
import { useQuery } from '@tanstack/react-query';
import { getRouteApi } from '@tanstack/react-router';
import { publicCommunityPostOptions } from '../../utils/publicPost.ts';
import FiLogoBlack from '../../assets/fi_logo_black.svg';
import { Anchor, Button, Container, Divider, Image } from '@mantine/core';
import '@mantine/notifications/styles.css';

const route = getRouteApi('/post/$id');

export function PublicPost() {
  const { id } = route.useParams();
  const { status, error, data } = useQuery(publicCommunityPostOptions(id));

  if (status === 'pending') {
    return <p>Loading...</p>;
  }

  if (status === 'error') {
    return <p>Error! {error.message}</p>;
  }

  if (status === 'success' && data) {
    switch (data.post.type) {
      case 'photo':
        communityPostEvents.photoPostViewed(id);
        break;
      case 'video':
        communityPostEvents.videoPostViewed(id);
        break;
      case 'text':
        communityPostEvents.textPostViewed(id);
        break;
      default:
        break;
    }
  }

  return (
    status === 'success' &&
    data && (
      <div>
        <header className={classes.header}>
          <div className={classes.inner}>
            <Anchor href={'https://tryfi.com'}>
              <Image src={FiLogoBlack} alt="Fi Logo" className={classes.logo} />
            </Anchor>

            <Button
              component={'a'}
              onClick={() => {
                communityPostEvents.joinFiHeaderClicked(id);
              }}
              href={'https://tryfi.com'}
              mt={-3}
              radius={'xl'}
              color={'black'}
              fz={16}
              fw={600}
            >
              Join Fi
            </Button>
          </div>
        </header>
        <Container size={800} className={classes.container}>
          <PublicPostCard post={data.post} pet={data.pet} />
        </Container>

        <Divider className={classes.mobileOnly} c={'#F1F1EF'} w={'100%'} my={16} />

        {data.relatedPosts && data.relatedPosts.length > 0 && (
          <PublicPostRecentPosts petName={data.pet.name} posts={data.relatedPosts} parentPostId={id} />
        )}

        <Container size={800} p={0}>
          <JoinFiCommunityCard
            onJoinClick={() => {
              communityPostEvents.joinFiFooterClicked(id);
            }}
          />
        </Container>
        <Footer />
      </div>
    )
  );
}
