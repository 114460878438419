import { createFileRoute } from '@tanstack/react-router';

export const Route = createFileRoute('/')({
  beforeLoad: () => {
    window.location.href = 'https://tryfi.com/';
  },
  component: Index,
});

function Index() {
  return <></>;
}
