import { createFileRoute } from '@tanstack/react-router';
import { liveSharingOptions, trackSessionVisit } from '../utils/liveSharing';
import { LiveSharing, LiveSharingError } from '../components/LiveSharing';
import { captureException } from '@sentry/react';

export const Route = createFileRoute('/track/$sharingId')({
  component: LiveSharing,
  errorComponent: LiveSharingError,
  onError: ({ error }) => {
    captureException(error);
  },
  loader: ({ context: { queryClient }, params }) => {
    return queryClient.ensureQueryData(liveSharingOptions(params.sharingId, true));
  },
  onEnter: async (match) => {
    // track this visit if this is the first time this browser has visited this live sharing session
    if (!localStorage.getItem(match.params.sharingId)) {
      await trackSessionVisit(match.params.sharingId);
      localStorage.setItem(match.params.sharingId, 'visited');
    }
  },
});
