import { Router, RouterProvider, createRouter } from '@tanstack/react-router';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import '@mantine/core/styles.css';
import '@mantine/carousel/styles.css';
import './fonts.css';
import { MantineProvider, colorsTuple } from '@mantine/core';
import { APIProvider } from '@vis.gl/react-google-maps';
import * as Sentry from '@sentry/react';
import { BrowserOptions as SentryBrowserOptions } from '@sentry/browser';

import { routeTree } from './routeTree.gen';
import { Notifications } from '@mantine/notifications';

/**
 * Sentry.io configuration
 */
function getSentryConfig(router: Router<any, any>): SentryBrowserOptions {
  const baseConfig: SentryBrowserOptions = {
    dsn: 'https://513151d2822d2b485e750ef72d5630aa@o4506027582619648.ingest.us.sentry.io/4507392149618688',
    environment: import.meta.env.MODE, // MODE is set by Vite see: https://vitejs.dev/guide/env-and-mode#modes
  };

  // RUM = Real User Monitoring
  if (import.meta.env.VITE_APP_RUM_ENABLE === 'true') {
    return {
      ...baseConfig,
      integrations: [
        // Performance Monitoring
        // https://docs.sentry.io/platforms/javascript/guides/react/features/tanstack-router/
        Sentry.tanstackRouterBrowserTracingIntegration(router),
        // Session Replay
        Sentry.replayIntegration({
          blockAllMedia: false, // default is true
          maskAllText: false, // default is true
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1,
      // Performance Monitoring
      tracePropagationTargets: ['localhost', /^https:\/\/public-api.tryfi.com/],
      tracesSampleRate: 0.01,
    };
  }

  return baseConfig;
}

const queryClient = new QueryClient();

const router = createRouter({
  routeTree,
  context: { queryClient },
  defaultPreload: 'intent',
  // Since we're using TanStack Query, we don't want loader calls to ever be stale
  // This will ensure that the loader is always called when the route is preloaded or visited
  // see: https://tanstack.com/router/latest/docs/framework/react/examples/basic-react-query-file-based
  defaultPreloadStaleTime: 0,
});

Sentry.init(getSentryConfig(router));

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

function App() {
  return (
    <MantineProvider
      theme={{
        fontFamily: 'MessinaSans, sans-serif',
        headings: { fontFamily: 'MessinaSans-Compact, sans-serif' },
        colors: {
          'fi-yellow': colorsTuple('#FFFA01'),
          'background-dark-primary': colorsTuple('#000000'),
          'background-dark-secondary': colorsTuple('#242424'),
          'background-dark-tertiary': colorsTuple('#3C3C3C'),
          'background-light-secondary': colorsTuple('#F3F3F2'),
          'type-dark-primary': colorsTuple('#FFFFFF'),
          'type-dark-secondary': colorsTuple('#9E9E9C'),
          'type-light-primary': colorsTuple('#000000'),
          'type-light-secondary': colorsTuple('#737373'),
          'stroke-dark-border': colorsTuple('#737373'),
        },
      }}
    >
      <Notifications position={'top-center'} />
      <APIProvider apiKey={import.meta.env.VITE_GOOGLE_MAPS_API_KEY}>
        <QueryClientProvider client={queryClient}>
          <RouterProvider router={router} />
        </QueryClientProvider>
      </APIProvider>
    </MantineProvider>
  );
}

export default App;
