import { useState } from 'react';
import { ErrorComponent, ErrorComponentProps, getRouteApi } from '@tanstack/react-router';
import { useQuery } from '@tanstack/react-query';
import { liveTrackingEvents } from '../lib/analytics/events.ts';
import { liveSharingOptions } from '../utils/liveSharing';
import { AxiosError } from 'axios';
import { AppShell, Avatar, Box, Button, Container, Grid, Group, Image, Stack, Text, Title } from '@mantine/core';
import { Carousel } from '@mantine/carousel';
import { ActiveMap } from './ActiveMap';
import { InactiveMap } from './InactiveMap';
import { TrackingFooter } from './TrackingFooter';
import { LiveSharingContactForm } from './LiveSharingContactForm/LiveSharingContactForm';
import { useOs } from '@mantine/hooks';
import { handleShareClick } from '../utils/handlers';

import DogIcon from '../assets/dog_icon.svg';
import ShareIosIcon from '../assets/share.svg';
import ShareAndroidIcon from '../assets/share-android.svg';
import classes from './LiveSharing.module.css';

const route = getRouteApi('/track/$sharingId');

export function LiveSharing() {
  const { sharingId } = route.useParams();
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [showContactForm, setShowContactForm] = useState(false);
  const { status, error, data } = useQuery(liveSharingOptions(sharingId, autoRefresh));

  const os = useOs();
  const shareIcon = os === 'android' ? ShareAndroidIcon : ShareIosIcon;

  const shareOnClick = () => {
    handleShareClick();
    liveTrackingEvents.shareClicked();
  };

  if (status === 'pending') {
    return <p>Loading...</p>;
  }

  if (status === 'error') {
    return <p>Error! {error.message}</p>;
  }

  if (!data.session.active && autoRefresh) {
    setAutoRefresh(false);
  }

  const photoSlides = data.pet.photos ? (
    data.pet.photos.map((url) => (
      <Carousel.Slide key={url} className={classes.slide}>
        <Image src={url} radius="lg" className={classes.photo} />
      </Carousel.Slide>
    ))
  ) : (
    <></>
  );

  const map = data.session.active ? (
    <ActiveMap
      locationData={data.location}
      profilePhoto={data.pet.profilePhoto}
      visitorCount={data.session.visitorCount}
      locationDelay={data.locationDelay}
    />
  ) : (
    <InactiveMap />
  );

  return (
    <AppShell header={{ height: '56px' }} footer={!showContactForm ? { height: '160px' } : undefined}>
      <AppShell.Header withBorder={false} className={classes.header}>
        <Group gap="8px">
          <Image src="/fi_logo.svg" />
          <Title size="16px" fw={700} c="dimmed">
            SEARCH PARTY
          </Title>
          <Button
            hiddenFrom="md"
            color="#E5E5E4"
            variant="subtle"
            radius="xs"
            classNames={{ root: classes.shareButtonMobileRoot, label: classes.shareButtonMobileLabel }}
            onClick={shareOnClick}
          >
            <Image src={shareIcon} width="18px" height="19px" />
          </Button>
          <Button
            visibleFrom="md"
            color="#E5E5E4"
            radius="xl"
            autoContrast
            classNames={{ root: classes.shareButtonDesktopRoot, label: classes.shareButtonDesktopLabel }}
            onClick={shareOnClick}
            leftSection={<Image src={shareIcon} width="20px" height="20px" />}
          >
            Share
          </Button>
        </Group>
      </AppShell.Header>
      <AppShell.Main>
        <Container p="7px 20px 20px" maw="1200px">
          <Stack>
            <Group gap="10px" wrap="nowrap">
              <Avatar src={data.pet.profilePhoto} radius="xl" size="lg" style={{ alignSelf: 'flex-start' }}>
                <Image src={DogIcon} />
              </Avatar>
              <Stack gap="5px">
                <Title size="32px" lineClamp={2} textWrap="wrap" lh="36px">
                  Have you seen {data.pet.name}?
                </Title>
                <Group gap="8px">
                  <Text c="dimmed" size="12px" className={classes.bioSubHeader}>
                    {data.pet.breed?.toLocaleUpperCase()}
                    {data.pet.breed && data.pet.age && '  |  '}
                    {data.pet.age?.toLocaleUpperCase()}
                  </Text>
                </Group>
              </Stack>
            </Group>
            <Grid>
              <Grid.Col span={{ base: 12, md: 'auto' }}>{map}</Grid.Col>
              <Grid.Col span={{ base: 12, md: 3.5 }}>
                <Group justify="center">
                  <Stack gap="20px">
                    <Box pos="relative">
                      <Title className={classes.photoLabel} c="white">
                        {data.pet.name}'s Photos
                      </Title>
                      <Carousel
                        withControls={false}
                        withIndicators={data.pet.photos && data.pet.photos?.length > 1}
                        classNames={{ indicators: classes.indicators, indicator: classes.indicator }}
                      >
                        {photoSlides}
                      </Carousel>
                    </Box>
                    <Stack className={classes.infoBox}>
                      <Stack gap="12px">
                        <Title order={3} size="20px">
                          How does Fi Live Tracking work?
                        </Title>
                        <Text size="14px" fw={500} lh="16px">
                          The Fi smart collar uses the AT&T LTE network for nationwide coverage to track {data.pet.name}
                          's live location.
                        </Text>
                      </Stack>
                      <Button
                        variant="outline"
                        radius="xl"
                        color="black"
                        component="a"
                        href="https://tryfi.com/learn"
                        target="_blank"
                        className={classes.learnMore}
                        onClick={() => {
                          liveTrackingEvents.learnMoreClicked();
                        }}
                      >
                        Learn more
                      </Button>
                    </Stack>
                  </Stack>
                </Group>
              </Grid.Col>
            </Grid>
          </Stack>
        </Container>
        {showContactForm && (
          <LiveSharingContactForm
            petName={data.pet.name}
            sharingId={sharingId}
            onClose={() => setShowContactForm(false)}
          />
        )}
      </AppShell.Main>
      {!showContactForm && data.session.active && data.location && (
        <AppShell.Footer withBorder={false}>
          <TrackingFooter
            locationData={data.location}
            onMessageOwner={() => {
              setShowContactForm(true);
              liveTrackingEvents.messageFormOpened();
            }}
          />
        </AppShell.Footer>
      )}
    </AppShell>
  );
}

export function LiveSharingError(props: ErrorComponentProps) {
  const { error } = props;
  if (error instanceof AxiosError) {
    // TODO: more parsing based on response
    return (
      <Box>
        <Title>Uh-oh!</Title>
        <Text>Something went wrong!</Text>
        <Text>Error: {error.message}</Text>
      </Box>
    );
  }
  return <ErrorComponent error={error} />;
}
