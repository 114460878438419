import { Image } from '@mantine/core';
import { notifications } from '@mantine/notifications';
import IconCopied from '../assets/icon-copied.svg';

// If share is supported, use the native share dialog, else copy the link and show notification indicating such
export function handleShareClick() {
  const shareData = { url: window.location.href };
  if (navigator.share && navigator.canShare(shareData)) {
    navigator.share(shareData);
  } else {
    navigator.clipboard.writeText(window.location.href);
    notifications.show({
      message: 'Link copied',
      withCloseButton: false,
      icon: <Image src={IconCopied} />,
      color: '#000000', // This is the icon background color (not intuitively named)
      radius: 12,
      p: 16,
      bg: '#000000', // This is the notification background color
      autoClose: 3_000, // 3 seconds
      styles: {
        description: {
          color: 'white',
          fontSize: '14px',
          fontWeight: 600,
        },
      },
    });
  }
}
