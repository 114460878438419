import { createFileRoute } from '@tanstack/react-router';
import { PublicPost } from '../components/PublicPost/PublicPost.tsx';
import { LiveSharingError } from '../components/LiveSharing';
import { publicCommunityPostOptions } from '../utils/publicPost.ts';
import { captureException } from '@sentry/react';

export const Route = createFileRoute('/post/$id')({
  component: PublicPost,
  errorComponent: LiveSharingError,
  onError: ({ error }) => {
    captureException(error);
  },
  loader: ({ context: { queryClient }, params }) => {
    return queryClient.ensureQueryData(publicCommunityPostOptions(params.id));
  },
});
