import { Anchor, Button, Container, Group, Image, Paper, Stack, Text } from '@mantine/core';
import { liveTrackingEvents } from '../lib/analytics/events.ts';
import { LocationData } from '../utils/liveSharing';
import { generateDirectionsUrl } from '../utils/liveSharing';

import classes from './TrackingFooter.module.css';

export function TrackingFooter(props: { locationData: LocationData; onMessageOwner: () => void }) {
  const { locationData, onMessageOwner } = props;

  return (
    <Paper className={classes.root} radius="0">
      <Container maw="1200px" p="0px" h="100%">
        <Stack h="100%" justify="space-around">
          <Stack>
            <Group wrap="nowrap" justify="space-between" align="flex-start" grow={false} gap="8px">
              <Stack gap="2px">
                <Text fw={700} fz="20px" w="310px" flex="1 0 auto" lineClamp={1}>
                  {locationData.address ?? `${locationData.lat}, ${locationData.long}`}
                </Text>
                <Text c="dimmed" fz="16px" fw={500} lineClamp={1}>
                  {locationData.cityState}
                </Text>
              </Stack>
              <Anchor
                href={generateDirectionsUrl(locationData)}
                target="_blank"
                flex={'0 0 auto'}
                onClick={() => {
                  liveTrackingEvents.directionsClicked();
                }}
              >
                <Image src="/directions_icon.svg" />
              </Anchor>
            </Group>
          </Stack>
          <Button onClick={onMessageOwner} color="black" radius="xl" flex="0 0 auto" h="45px">
            <Text fz="16px" fw={600}>
              Message owner
            </Text>
          </Button>
        </Stack>
      </Container>
    </Paper>
  );
}
