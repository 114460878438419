import { queryOptions } from '@tanstack/react-query';
import axios from 'axios';

interface PublicPostSummary {
  id: string;
  pet: PublicPet;
  post: PublicPost;
  relatedPosts: PublicPost[];
}

export interface PublicPet {
  name: string;
  avatarUrl: string;
  cityState?: string;
  fiHandle?: string;
}

export type PublicPost = PublicVideoPost | PublicPhotoPost | PublicTextPost;

export interface AspectRatio {
  width: number;
  height: number;
}

interface PublicPostBase {
  id: string;
  type: PostType;
  likeCount: number;
  commentAvatarUrls: string[];
  commentCount: number;
}

export interface PublicVideoPost extends PublicPostBase {
  type: PostType.VIDEO;
  mp4Url: string;
  thumbnailUrl: string;
  caption?: string;
  aspectRatio: AspectRatio;
}

export interface PublicPhotoPost extends PublicPostBase {
  type: PostType.PHOTO;
  url: string;
  aspectRatio: AspectRatio;
  caption?: string;
}

export interface PublicTextPost extends PublicPostBase {
  type: PostType.TEXT;
  text: string;
  textColorHex: string;
  backgroundColorHex?: string;
}

export enum PostType {
  VIDEO = 'video',
  PHOTO = 'photo',
  TEXT = 'text',
}

export const publicCommunityPostOptions = (id: string) =>
  queryOptions({
    queryKey: ['id', { id }],
    queryFn: () => getSessionData(id),
  });

async function getSessionData(id: string) {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';
  const response = await axios.get<PublicPostSummary>(`${webApiBaseUrl}/community-public/post/${id}`);
  return response.data;
}
