import { queryOptions } from '@tanstack/react-query';
import axios from 'axios';
import { DateTime } from 'luxon';

export interface LocationData {
  lat: number;
  long: number;
  accuracyRadius: number;
  address: string | null;
  cityState: string | null;
  /** Datetime string in ISO format */
  timestamp: string;
  /** Datetime string in ISO format */
  nextReportExpectedBy: string;
}

export interface LiveSharingSessionDataType {
  session: { active: boolean; visitorCount?: number };
  pet: {
    name: string;
    profilePhoto?: string;
    photos?: string[];
    breed?: string;
    age?: string;
    batteryPercent?: number;
  };
  location: LocationData | null;
}

export const liveSharingOptions = (sharingId: string, autoRefresh: boolean) =>
  queryOptions({
    queryKey: ['sharingSession', { sharingId }],
    queryFn: () => getSessionData(sharingId),
    refetchInterval: autoRefresh ? 10_000 : false,
  });

async function getSessionData(sharingId: string) {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';
  const response = await axios.get<LiveSharingSessionDataType>(
    `${webApiBaseUrl}/live-sharing/ldmSession?sharingId=${sharingId}`,
  );
  const isDelayed = response.data.location
    ? DateTime.fromISO(response.data.location?.nextReportExpectedBy) < DateTime.now()
    : false;

  return {
    ...response.data,
    locationDelay: {
      isDelayed,
      delayMins:
        response.data.location && isDelayed
          ? Math.floor(-1 * DateTime.fromISO(response.data.location.timestamp).diffNow('minutes').minutes)
          : null,
    },
  };
}
/**
 * Generate a URL that will open Google Maps in Directions mode
 * @see https://developers.google.com/maps/documentation/urls/get-started#directions-action
 * @param locationData
 * @returns A URL-encoded string
 */
export function generateDirectionsUrl(locationData: LocationData) {
  const destinationString = locationData.address
    ? encodeURI(locationData.address)
    : encodeURI(`${locationData.lat},${locationData.long}`);
  return `https://www.google.com/maps/dir/?api=1&destination=${destinationString}`;
}

/**
 * Record a visit to a given sharing session
 * @param sharingId
 */
export async function trackSessionVisit(sharingId: string) {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';
  try {
    await axios.post(`${webApiBaseUrl}/live-sharing/trackVisitor`, { sharingId });
  } catch (err) {
    console.error('Unable to record visit for sharing session');
  }
}

/**
 * Send a message to the owners of the dog in the live sharing session
 * @param data Name, phone number, and the message text
 */
export async function sendMessage(data: { sharingId: string; name: string; phoneNumber: string; message: string }) {
  const webApiBaseUrl = import.meta.env.VITE_WEB_REST_API ?? 'http://localhost:8080/api';
  await axios.post(`${webApiBaseUrl}/live-sharing/message`, data);
}
